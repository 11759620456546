import './App.css';
import Main from './component/main';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Scrollbar from 'react-smooth-scrollbar';
import { useEffect, useState } from 'react';

import Loader from "./assets/static/loader.json";

import Lottie from "react-lottie";

function App() {

  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => { setShow(true) }, 3000)
  }, [])

  return (
    <div className="App">
      {show ?
        (<Scrollbar damping={0.2}>
          <Main />
        </Scrollbar>)
        :
        (
          <div className="loader">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: Loader,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                }
              }}
              height={200}
              width={300}
              background="transparent"
              isClickToPauseDisabled
            />
            <p className="branding-text">
              Cappricio Securities
            </p>
          </div>
        )
      }
    </div>
  );
}

export default App;
