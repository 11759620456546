import React from 'react';

import "../assets/style/card.css";
import Tilt from 'react-tilt'
import Flip from 'react-reveal/Flip';

const Cards = ({ title, list }) => {

    const handleOnClick = (name) => {
        if (title === "course") {
            handleUniversityCardsClick(name)
        }
    }

    const handleUniversityCardsClick = (courseName) => {
        let url = "";
        switch (courseName) {
            case "Web Hacking & Bug Bounty":
                url = "https://university.cappriciosec.com/webhacking.html"
                break;
            case "Ethical Hacking & penetration testing":
                url = "https://university.cappriciosec.com/ethicalhacking.html"
                break;
            case "Mobile application Hacking & bug bounty":
                url = "https://university.cappriciosec.com/mobileappbugbounty.html"
                break;
            case "Hardware & IOT development":
                url = "https://university.cappriciosec.com/hardwareandiot.html"
                break;
            case "Offensive java & IOT hacking":
                url = "https://university.cappriciosec.com/offensivejava.html"
                break;
            case "Offensive social media & mobile Hacking":
                url = "https://university.cappriciosec.com/offensivesocialmedia.html"
                break;
            default:
                url = undefined;
        }

        if (url) {
            const link = document.createElement("a")
            link.href = url;
            link.target = "_blank"
            link.rel = "noopenner"
            link.click()
        }
    }

    return (
        <div className="card-container">
            {list.map((item, index) => {
                return (
                    <>
                        <Flip right>
                            <div onClick={() => handleOnClick(item)}>
                                <Tilt options={{ max: 30 }} style={{ cursor: title === "course" ? "pointer" : "unset" }} key={index + Math.random() + ""} className={`card ${title === "course" && "card-margin"}`}>
                                    <div key={index + Math.random() + ""} className="card__background"></div>
                                    <div key={index + Math.random() + ""} className="card__content">
                                        <p key={index + Math.random() + ""} className="card__category">{title}</p>
                                        <h3 key={index + Math.random() + ""} className="card__heading">{item}</h3>
                                    </div>
                                </Tilt>
                            </div>
                        </Flip>
                    </>
                )
            })}
        </div>
    );
}

export default Cards;