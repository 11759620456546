import React from 'react';

import "../assets/style/textSpinner.css";
import "../assets/style/textSpinner.css";

const TextSpinner = () => {
    return ( 
        <div className="content">
        <div className="content__container">
            <p className="content__container__text">
            What's Our
            </p>
            <ul className="content__container__list">
            <li className="content__container__list__item">Power</li>
            <li className="content__container__list__item">Intelligence</li>
            <li className="content__container__list__item">Strength</li>
            <li className="content__container__list__item">Brain</li>
            </ul>
        </div>
        </div>
     );
}
 
export default TextSpinner;