import React from 'react';

import "../assets/style/main.css";
import "../assets/style/glitch.css";
import "../assets/style/mobile.css";

import Typist from 'react-text-typist';

import Lottie from "react-lottie";

import sphere from "../assets/static/sphere.json";

import Cards from "./cards";

import Tech from "../assets/static/tech.json";

import university from "../assets/static/university.json";

import TextSpinner from './textSpinner';

// import TeamCard from './teamCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    faYoutube,
    faInstagram,
    faLinkedin
} from "@fortawesome/free-brands-svg-icons";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons"

import { faPhone } from "@fortawesome/free-solid-svg-icons"

import { motion } from "framer-motion";

import { Fade, Reveal } from 'react-reveal';

// import Carousel from "./clientCarousel";

import Clients from './clients';

const Main = () => {

    const transition = { duration: 1.5, ease: "easeInOut" };

    return (
        <>
            <div className="p1">
                <motion.div className="navbar" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ...transition, ease: "easeIn" }}>
                    <div className="nav-left">
                        <img className="logo" src="https://cappriciosec.com/logo.png" alt="" />
                        <h1 className="logo-text">Cappricio Securities</h1>
                    </div>
                </motion.div>
                <div className="hero">
                    <motion.div className="hero-left" initial={{ offsetDistance: "0%", scale: 1.2 }}
                        animate={{ offsetDistance: "100%", scale: 1 }}
                        transition={{ ...transition, ease: "easeIn", duration: 1.2 }}>
                        <div className="hero-left-wrapper">
                            <h2 className="glitch">A future to cyber era</h2>
                            <Typist className="desp-text" typingSpeed={100} sentences={["S_c_rity is incomplete without us"]} />
                        </div>
                    </motion.div>
                    <motion.div className="hero-right" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ...transition, ease: "easeIn" }}>
                        <Lottie
                            animationData={sphere}
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: sphere,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            background="transparent"
                            isClickToPauseDisabled
                        />
                    </motion.div>
                </div>
            </div>
            <div className="p2">
                <Cards title="service" list={["Web and API Pentesting", "Cloud Security and pentesting", "Mobile application Security and pentesting", "Hardware and IOT pentesting"]} />
            </div>
            {/* <Carousel /> */}
            <Clients />
            <div className="p3">
                <Reveal effect="fadeInUp">
                    <div className="text-spinner-wrapper">
                        <TextSpinner />
                    </div>
                </Reveal>
                <div className="p3-context">
                    <Fade left>
                        <div className="wi-left">
                            <div className='wi-left-wrapper'>
                                <div>
                                    <div className="grd-header-wrapper">
                                        <h1 className='header'>Introducing</h1>
                                    </div>
                                    <div className="grd-sub-header-wrapper">
                                        <h2 className='sub-header'>Web Intellect</h2>
                                    </div>
                                    <div className="desp">
                                        AI-based intelligence software for Web Security & Pen-testing
                                    </div>
                                </div>
                                <button className="km-btn" onClick={() => {
                                    const link = document.createElement('a');
                                    link.href = "https://cappriciosec.com/product";
                                    link.target = "_blank";
                                    link.rel = "noreferrer"
                                    link.click();
                                }}>Know more</button>
                            </div>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="wi-right">
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: Tech,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                background="transparent"
                                isClickToPauseDisabled
                            />
                        </div>
                    </Fade>
                </div>
            </div>
            <div className="p4">
                <div className="university-wrapper">
                    <Fade left>
                        <div className="uni-left">
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: university,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                background="transparent"
                                isClickToPauseDisabled
                            />
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="uni-right">
                            <p className="uni-header">
                                Cappricio
                            </p>
                            <p className="uni-des">
                                The platform where you learn is as much important as what you learn. We teach you Cyber Security / Criminal Investigation to legally attack real-world applications. The promises are meant to be on live solvation over environmental practices.
                            </p>
                        </div>
                    </Fade>
                </div>
                <div className="uni-course">
                    <Cards title="course" list={["Web Hacking & Bug Bounty", "Ethical Hacking & penetration testing", "Mobile application Hacking & bug bounty", "Hardware & IOT development", "Offensive java & IOT hacking", "Offensive social media & mobile Hacking"]} />
                </div>
            </div>
            {/* <div className="p5">
                <Zoom top cascade>
                    <p className="p5-header">
                        Master Minds Behind
                    </p>
                </Zoom>
                <TeamCard />
            </div> */}
            <div className="footer">
                <Fade bottom cascade>
                    <div className="social">
                        <a className="social-btn" href="https://www.linkedin.com/company/cappricio-securities" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="fab" icon={faLinkedin} size='1x' />
                        </a>
                        <a className="social-btn" href="https://instagram.com/cappriciosec" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="fab" icon={faInstagram} size='1x' />
                        </a>
                        <a className="social-btn" href="https://www.youtube.com/karthithehacker" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="fab" icon={faYoutube} size='1x' />
                        </a>
                        <a className="social-btn" href="mailto:contact@cappriciosec.com" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="fab" icon={faEnvelope} size='1x' />
                        </a>
                        <a className="social-btn" href="tel:+919500049738" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="fab" icon={faPhone} size='1x' />
                        </a>
                    </div>
                </Fade>
                <motion.p className="trading-mark" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, ease: "easeOut" }}>
                    © Copyright {new Date().getFullYear()} CAPPRICIO SECURITIES | All Rights Reserved
                </motion.p>
            </div>
        </>
    );
}

export default Main;