/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import "../assets/style/clients.css";

const Clients = () => {
    return (
        <>
            <div className="client-header">We</div>
            <div className="client-wrap">
                <div className="client google">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                        <ul className="project-list">
                        </ul>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg" />
                </div>
                <div className="client microsoft">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo smaller"
                        src="https://upload.wikimedia.org/wikipedia/commons/b/bf/LG_logo_%282015%29.svg" />
                </div>
                <div className="client logitech">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Logitech_logo.png" />
                </div>
                <div className="client oculus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/pinterest.svg" />
                </div>
                <div className="client skype">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo smaller" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/centrify.svg" />
                </div>
                <div className="client amd">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/snap.svg" />
                </div>
                <div className="client treehouse">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo"
                        src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/eero.svg" />
                </div>
                <div className="client nickelodeon">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/android.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/convertkit.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo"
                        src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/fitbit.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo"
                        src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/geniebelt.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/lime.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/socrata.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/stripo.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/tripadvisor.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/upwork.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/viator.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://raw.githubusercontent.com/aadarshvelu/cs-static/cd6275bfc1834feed477f2b6c41aba0015e28c87/clients/wise.svg" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/2880px-Microsoft_logo_%282012%29.svg.png" />
                </div>
                <div className="client toysrus">
                    <div className="client-more-less"></div>
                    <div className="client-meta">
                        <div className="client-close"></div>
                    </div>
                    <div className="overflow-wrapper">
                    </div>
                    <img className="client-logo"
                        src="https://logos.bugcrowdusercontent.com/logos/fef2/9f40/584e8fec/1b8e12c8a5f7766aa219c45467eb3926_square_netgwar.jpg" />
                </div>
            </div>
        </>
    );
}

export default Clients;